// 黑名单
<template>
    <div class="menu">
        <div class="screening">
            <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item title="筛选框" name="1">
                    <div class="screening-out">
                        <el-form :inline="true" class="demo-form-inline">
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="主叫号码">
                                        <el-input v-model="searchFrom.telA" placeholder="请输入需要查询的手机号" size="mini"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="被叫号码">
                                        <el-input v-model="searchFrom.telB" placeholder="请输入需要查询的手机号" size="mini"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="创建时间">
                                        <el-date-picker v-model="valueTime" type="datetimerange" start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        :clearable="false"
                                        value-format="YYYY-MM-DD HH:mm:ss" size="mini" :default-time="defaultTime">
                                      </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <!-- <el-form-item> -->
                                        <div class="title-buttom">
                                            <el-button class="cancel" size="mini" @click="reset" round>重置</el-button>
                                            <el-button type="primary" size="mini" class="confirmAdd" @click="onSubmit" round>查询</el-button>
                                        </div>
                                    <!-- </el-form-item> -->
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <el-row>
            <el-col :span="12">
                <div class="title-name">
                    数据列表
                </div>
            </el-col>
            <el-col :span="12">
                <div class="title-buttom">
                    <el-tooltip content="刷新列表" placement="top" effect="light">
                        <el-button class="editor" size="mini" icon="el-icon-refresh-right" @click="reset" circle></el-button>
                    </el-tooltip>
                    <!-- <el-tooltip content="新增菜单" placement="top" effect="light">
                        <el-button class="editor" size="mini" icon="el-icon-plus" @click="addMenu" circle></el-button>
                    </el-tooltip> -->
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div>
                   <el-table
                    class="eltable"
                    v-loading="loading"
                    ref="multipleTable"
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    :header-cell-style="{background:'#e0e5ff',color:'#24252F'}"
                    row-key="id"
                    default-expand-all
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                    @selection-change="handleSelectionChange">
                        <!-- <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column> -->
                        <el-table-column
                        label="主叫号码">
                        <template #default="scope">{{ scope.row.telA == null|| scope.row.telA == ''?'-': scope.row.telA}}</template>
                        </el-table-column>
                        <el-table-column
                        label="被叫号码">
                        <template #default="scope">{{ scope.row.telB == null|| scope.row.telB == ''?'-': scope.row.telB}}</template>
                        </el-table-column>
                        <el-table-column
                        label="通过/拦截">
                        <template #default="scope">
                            <span :class="scope.row.isPass == 0?'pass':'unpass'">{{ scope.row.isPassText == null|| scope.row.isPassText == ''?'-': scope.row.isPassText}}</span>
                        </template>
                        </el-table-column>
                        <el-table-column
                        label="类别">
                        <template #default="scope">{{ scope.row.typeText == null|| scope.row.typeText == ''?'-': scope.row.typeText}}</template>
                        </el-table-column>
                        <el-table-column
                        label="第三方接口">
                        <template #default="scope">{{ scope.row.useApiText == null|| scope.row.useApiText == ''?'-': scope.row.useApiText}}</template>
                        </el-table-column>
                        <el-table-column
                        label="费用">
                        <template #default="scope">
                            <span class="priceColor">￥{{ scope.row.price == null|| scope.row.price == ''?'-': scope.row.price}}</span>
                        </template>
                        </el-table-column>
                        <el-table-column
                        label="创建时间">
                        <template #default="scope">{{ scope.row.createTime == null|| scope.row.createTime == ''?'-': scope.row.createTime}}</template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
        <div class="pager">
            <div>
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                background
                :page-sizes="[10, 20, 30, 40]"
                :page-size="searchFrom.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import blackCheck from '@/api/open/black/blackCheck'
import { IconFont } from "@/utils/iconfont"
import { getDate } from '@/utils/timeToChoose.js'
export default {
    components: {
        IconFont,
    },
    
    data () {
        return {
            tableHeight:'',
            searchFrom:{
                page:1,
                pageSize:10,
                telA:'',
                telB:'',
                createTimeBegin:'',
                createTimeEnd:''
            },
            total:0,
            currentPage: 1,//分页
            multipleSelection: [],//选中集合
            valueTime:'',//选中时间段
            defaultTime: [
                new Date(2000, 1, 1, 0, 0, 0),
                new Date(2000, 2, 1, 23, 59, 59)
            ], //默认时间 '12:00:00', '08:00:00'
            activeNames: [],//折叠面板
            tableData: [],//菜单表
            // loding 状态启用
            loading: true,
         
          
          
        }
    },
    created() {
        this.tableHeight = this.$store.state.tableHeight
    },
    watch: {

    },
    mounted() {
        this.valueTime = [getDate(0) + ' 00:00:00', getDate(0) + ' 23:59:59']
        this.getDataList(true)
       
       
    },
    methods: {
        // 查询
        onSubmit() {
           this.getDataList(true)
        },
        // 重置
        reset() {
            this.searchFrom = {
                page: this.currentPage,
                pageSize: 10,
                searchValue:'',
                createTimeBegin:'',
                telA:'',
                telB:'',
                createTimeEnd:''
            },
            this.valueTime = [getDate(0) + ' 00:00:00', getDate(0) + ' 23:59:59']
            this.getDataList(true)
        },
        // 获取数据列表
        getDataList(type) {
          
            this.loading = type
            if (this.valueTime != null) {
        this.searchFrom.createTimeBegin = this.valueTime[0]
        this.searchFrom.createTimeEnd = this.valueTime[1]
      } else {
        this.searchFrom.createTimeBegin = ''
        this.searchFrom.createTimeEnd = ''
      }
        console.log(this.valueTime[0],this.valueTime[1],'00000000000000000');
            blackCheck.blackCheckList({
                ...this.searchFrom
            }).then((res) => {
                this.tableData = res.data.records
                this.total = res.data.total
                this.loading = false
            })
        },
         formatDate(date, format) {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      };
      return new Date(date).toLocaleString('zh-CN', options).replace(/\//g, '-').replace(/,/, '');
    },
        // 分页
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.searchFrom.pageSize = val
            this.getDataList(true)
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.searchFrom.page = val
            this.currentPage =val
            this.getDataList(true)
        },
        // 折叠面板
        handleChange(val) {
            console.log(val);
        },
        // 多选
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 批量删除
        deleteData() {
            console.log(this.multipleSelection)
        },
    }
}
</script>

<style lang="scss" scoped>
.menu{
    height: 100%;
    overflow-y: overlay;
    padding-right: 18px;
    .title-name{
        font-size: .9375rem;
        font-weight: 600;
        color: #303133;
    }
    .top-first{
        .search-box{
            width: 18.75rem;
        }
    }
}
.screening{
    margin-bottom: .9375rem;
}
.el-row {
    margin-bottom: .9375rem;
    &:last-child {
        margin-bottom: 0;
    }
}

.title-buttom{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}
.editor{
    color: #0170ED;
    background: #E3F0FF;
}
.center-body{
    margin: 0 1.875rem;
}
.deletetable{
    color: #FF845B;
    background: #FFE4DB;
}
.deletetable-other{
    margin-right: 1.25rem;
}
.confirmAdd{
    background: #637DFF;
    color: #fff;
}
.cancel{
    color: #637DFF;
    border: #637DFF solid 1px;
}
.screening-out{
    margin: 0 .9375rem;
}
.out-icon:hover{
    background: #E3F0FF;
    color: #0170ED;
}
.pager {
    display: flex;
    justify-content: flex-end;
}
.pass{
    color: #67C23A;
}
.unpass{
    color: #F56C6C;
}
.priceColor{
    color: #409EFF;
}
</style>

<style lang="scss">
.menu .el-collapse-item__content {
    padding-bottom: 0 !important;
}
.eltable {
    overflow-y: overlay;

}
</style>